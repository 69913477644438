<template>
  <div>
    <div class="container">
      <!-- 左边视频区域 -->
      <div id="Player"></div>
      <!-- 右边控制区域 -->
      <div class="video-control">
        <!-- 云台控制 -->
        <div class="control">
          <div class="title">
            云台控制：
          </div>
          <!-- 按钮区域 -->
          <div class="btn">
            <!-- 上 -->
            <!-- <el-button icon="el-icon-top" @click="moveTop"></el-button> -->
            <el-button
              icon="el-icon-top"
              @mousedown.native="moveTop"
              @mouseup.native="stopMove"
            ></el-button>
            <!-- 右 -->
            <el-button
              icon="el-icon-right"
              @mousedown.native="moveRight"
              @mouseup.native="stopMove"
            >
            </el-button>
            <!-- 下 -->
            <el-button
              icon="el-icon-bottom"
              @mousedown.native="moveBottom"
              @mouseup.native="stopMove"
            ></el-button>
            <!-- 左 -->
            <el-button
              icon="el-icon-back"
              @mousedown.native="moveLeft"
              @mouseup.native="stopMove"
            ></el-button>
          </div>
          <!-- 变倍 -->
          <div class="zoom">
            <div class="minus">
              <el-button
                icon="el-icon-minus"
                @mousedown.native="moveZoomMin"
                @mouseup.native="stopMove"
              ></el-button>
            </div>
            <div class="tit">变倍</div>
            <div class="plus">
              <el-button
                icon="el-icon-plus"
                @mousedown.native="moveZoomPlus"
                @mouseup.native="stopMove"
              ></el-button>
            </div>
          </div>
          <!-- 按钮 -->
        </div>
        <!-- 云台功能 -->
        <!-- <div class="func">
          <div class="title">
            云台功能：
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
// import WasmPlayer from '@easydarwin/easywasmplayer'
export default {
  data() {
    return {
      player: '',
      url: '',
      // 设备id
      id: '',
      // 移动
      videoDeviceMoveForm: {
        pan: '', // 水平，正值表示向左水平转动，-1.0-1.0
        tilt: '', // 垂直，正值表示向上水平转动
        zoom: '' // 缩放速度参数，正值表示放大。取值：-1.0~1.0
      }
    }
  },
  created() {
    // 接收上一级传过来的id
    this.id = this.$route.query.key
    console.log(this.id)
    // 获取视频链接
    this.getVideoUrl()
    // 刷新链接
    this.refreshVideoUrl()
  },
  mounted() {
    // 实例化播放器
    this.player = new window.WasmPlayer(null, 'Player', window.callbackfun)
    // 调用播放
    this.player.play(this.url, 1)
  },
  // 销毁
  beforeDestroy() {
    this.player.destroy()
  },
  methods: {
    callbackfun(e) {
      console.log('callbackfun', e)
    },
    // 获取链接
    async getVideoUrl() {
      const { data: res } = await this.$http.get(
        `video/videoSurveillanceDevice/${this.id}/start`
      )
      if (res.code !== 0) {
        return this.$message.error('获取视频链接失败')
      }
      this.url = res.data.url
      console.log(this.url)
      this.player.play(this.url, 1)
    },
    // 刷新链接
    async refreshVideoUrl() {
      const { data: res } = await this.$http.get(
        `video/videoSurveillanceDevice/${this.id}/urlRefresh`
      )
      if (res.code !== 0) {
        return this.$message.error('刷新视频链接失败')
      }
      this.url = res.data.url
      this.player.play(this.url, 1)
    },
    // 向上移动
    async moveTop() {
      this.videoDeviceMoveForm.tilt = 0.5
      const { data: res } = await this.$http.post(
        `video/videoSurveillanceDevice/${this.id}/continuousMove`,
        this.videoDeviceMoveForm
      )
      if (res.code !== 0) {
        this.$message.error('向上移动失败')
      }
    },
    // 向下移动
    async moveBottom() {
      this.videoDeviceMoveForm.tilt = -0.5
      const { data: res } = await this.$http.post(
        `video/videoSurveillanceDevice/${this.id}/continuousMove`,
        this.videoDeviceMoveForm
      )
      if (res.code !== 0) {
        this.$message.error('向下移动失败')
      }
    },
    // 向右移动
    async moveRight() {
      this.videoDeviceMoveForm.pan = -0.5
      const { data: res } = await this.$http.post(
        `video/videoSurveillanceDevice/${this.id}/continuousMove`,
        this.videoDeviceMoveForm
      )
      if (res.code !== 0) {
        this.$message.error('向右移动失败')
      }
    },
    // 向左移动
    async moveLeft() {
      this.videoDeviceMoveForm.pan = 0.5
      const { data: res } = await this.$http.post(
        `video/videoSurveillanceDevice/${this.id}/continuousMove`,
        this.videoDeviceMoveForm
      )
      if (res.code !== 0) {
        this.$message.error('向左移动失败')
      }
    },
    // 变倍小
    async moveZoomMin() {
      this.videoDeviceMoveForm.zoom = -0.5
      const { data: res } = await this.$http.post(
        `video/videoSurveillanceDevice/${this.id}/continuousMove`,
        this.videoDeviceMoveForm
      )
      if (res.code !== 0) {
        this.$message.error('缩小失败')
      }
    },
    // 变倍大
    async moveZoomPlus() {
      this.videoDeviceMoveForm.zoom = 0.5
      const { data: res } = await this.$http.post(
        `video/videoSurveillanceDevice/${this.id}/continuousMove`,
        this.videoDeviceMoveForm
      )
      if (res.code !== 0) {
        this.$message.error('放大失败')
      }
    },
    // 停止转动
    async stopMove() {
      const { data: res } = await this.$http.post(
        `video/videoSurveillanceDevice/${this.id}/stopAdjust`
      )
      if (res.code !== 0) {
        this.$message.error('停止失败')
      }
      // 清空
      this.videoDeviceMoveForm = {}
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  display: flex;
  width: 1100px;
  padding-top: 20px;
}
.video {
  flex: 1;
  height: 600px;
}
.prism-player {
  height: 100% !important;
}
.video-control {
  width: 300px;
  margin-left: 50px;
  // background-color: pink;
}
.control .title,
.func .title {
  font-weight: 700;
}
.btn {
  position: relative;
}
.el-button:nth-child(1) {
  position: absolute;
  left: 130px;
  top: 50px;
}
.el-button:nth-child(2) {
  position: absolute;
  left: 200px;
  top: 100px;
}
// .button {
//   position: absolute;
//   left: 200px;
//   top: 100px;
// }
.el-button:nth-child(3) {
  position: absolute;
  left: 120px;
  top: 150px;
}
.el-button:nth-child(4) {
  position: absolute;
  left: 40px;
  top: 100px;
}
.el-button:nth-child(5) {
  position: absolute;
  left: 130px;
  top: 110px;
}
.el-icon-top,
.el-icon-bottom,
.el-icon-back,
.el-icon-right {
  font-size: 20px;
  font-weight: 700;
}
.func {
  margin-top: 300px;
}
.zoom {
  position: relative;
  top: 250px;
  display: flex;
  justify-content: space-between;
  width: 300px;
  height: 100px;
  line-height: 100px;
}
.minus {
  position: absolute;
  left: -70px;
  top: -50px;
}
.tit {
  position: absolute;
  left: 140px;
  top: -30px;
}
.plus {
  position: absolute;
  left: 70px;
  top: -50px;
}
</style>
